import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store/store';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { makeLink } from '@/utils/Link';
import { LinkHandler, Typography } from '@/components/atoms';
import { closePromoBar, stopSendImpression } from '@/store/actions/ui';
import Image from 'next/image';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { IPromoBar } from '@/types/contentful/contentful';
import { dispatchCustomEvent } from '@/utils/Impressions';
const Container = styled.div`
  padding: 16px;
  background-color: ${props => props.theme.promoBar.backgroundColor};
  text-align: center;
  position: relative;
  color: ${props => props.theme.promoBar.color};
`;

const ImageContainer = styled.div`
  margin-bottom: 8px;
`;

const Heading = styled(Typography)``;

const Description = styled(Typography)`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const Close = styled.div`
  text-align: right;
  margin-bottom: 8px;
  ${props => props.theme.breakpoints.up('sm')} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    padding: 12px;
    cursor: pointer;
    color: inherit;
  }
`;

const PromoBar: React.FC<IPromoBar> = props => {
  const {
    fields: { activePromoName, heading, description, link, image },
  } = props;

  const dispatch = useDispatch();

  const showPromoBar = useSelector(
    (state: RootState) => state.uiReducer.promoBar.show
  );

  const sendImpression = useSelector(
    (state: RootState) => state.uiReducer.promoBar.sendImpression
  );

  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const activePromo = useSelector(
    (state: RootState) => state.userReducer.customer?.activePromo?.value
  );

  const options = {
    renderText: (text: any) =>
      text
        .split('\n')
        .flatMap((text: any, i: number) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: React.ReactNode) => children,
    },
  };

  let desc = null;
  if (description) {
    desc = documentToReactComponents(description, options);
  }

  let url = null;
  if (link && link.fields?.href) {
    url = makeLink(link.fields?.type, link.fields?.href, null);
  }
  let linkText = link && link.fields?.text ? link.fields.text : '';

  useEffect(() => {
    if (sendImpression) {
      dispatchCustomEvent('promoBarImpression', {
        event: `view_promotion`,
        promotion_name: activePromoName,
      });
    }
    dispatch(stopSendImpression());
  }, []);

  const closePromoBarHandler = () => {
    dispatch(closePromoBar());
  };

  return showPromoBar && isLogged && activePromoName === activePromo ? (
    <Container>
      <Close onClick={closePromoBarHandler}>
        <CloseOutlinedIcon />
      </Close>

      {image &&
        image.fields &&
        image.fields.file &&
        image.fields.file.details &&
        image.fields.file.details.image && (
          <ImageContainer>
            <Image
              alt={''}
              src={image.fields.file.url}
              width={image?.fields?.file?.details?.image?.width}
              height={image?.fields?.file?.details?.image?.height}
              unoptimized={true}
            />
          </ImageContainer>
        )}
      {heading && <Heading>{heading}</Heading>}
      {desc && (
        <Description datatype="body_1">
          {desc}{' '}
          {url && linkText && (
            <LinkHandler href={url} passHref>
              {linkText}
            </LinkHandler>
          )}
        </Description>
      )}
    </Container>
  ) : null;
};

export default PromoBar;
